<template>
    <div class="modal" :class="{ 'rating-modal': isRatingModal }">
        <div class="modal__wrapper" v-click-outside="handleClick">
            <CRButton v-if="!isRatingModal && !hideCloseButton" class="close-btn" close @click="handleClick" />
            <slot />
        </div>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import vClickOutside from 'click-outside-vue3';

    export default {
        name: 'BasicModal',
        components: { CRButton },
        props: {
            isRatingModal: {
                type: Boolean,
                default: false,
            },
            hideCloseButton: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['close'],
        directives: {
            clickOutside: vClickOutside.directive,
        },
        mounted() {
            document.body.classList.add('overflow-hidden');
        },
        beforeUnmount() {
            document.body.classList.remove('overflow-hidden');
        },
        methods: {
            handleClick() {
                document.body.classList.remove('overflow-hidden');
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        z-index: $index-1000;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        padding: 12px;

        background-color: rgba(0, 0, 0, 0.7);

        @include column-align-center-justify-center;

        transition: opacity 0.3s ease;

        &__wrapper {
            @include column-align-center-justify-center;

            position: relative;

            width: 100%;
            max-width: 560px;

            background: #ffffff;

            overflow-y: scroll;

            border-radius: 10px;

            .close-btn {
                position: absolute;
                top: 12px;
                right: 12px;
                z-index: $index-100;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .rating-modal {
        padding: 0;

        .modal__wrapper {
            max-width: 100%;

            background: none;
        }
    }
</style>
