<template>
    <div>
        <div ref="target" />
        <div class="navbar" :class="{ sticking }">
            <div class="nav__wrapper">
                <router-link to="/" class="logo-link">
                    <LogoFullSvg class="logo-icon" />
                </router-link>
                <CRSelect
                    class="city-select"
                    :data="citiesForSelect"
                    :selected="selectedCity?.id"
                    @select="setSelectedCity"
                >
                    <template #icon>
                        <LocationSvg class="icon-location" />
                    </template>
                </CRSelect>
            </div>

            <nav class="nav__wrapper">
                <ul>
                    <li v-for="(link, index) in navbarLinks" :key="index">
                        <router-link :to="{ name: link.linkName }">
                            {{ link.title }}
                        </router-link>
                    </li>
                </ul>
                <LocationSvg class="select-city-icon" @click="isCityModalOpen = true" />
                <CitySelectModal
                    v-if="isCityModalOpen"
                    :data="citiesForSelect"
                    :selected="selectedCity?.id"
                    @select="setSelectedCity"
                    @close="isCityModalOpen = false"
                />
                <div class="cart-icon__wrapper" @mouseover="onCartHover">
                    <router-link
                        @click="cartWrapperHover = false"
                        :to="{ name: 'cart' }"
                        :class="{ 'cart-icon__wrapper-active': cart.length }"
                        class="icon__wrapper"
                    >
                        <CartSvg class="icon cart-icon" />
                    </router-link>
                    <CartHoverSection
                        v-if="cartWrapperHover"
                        v-click-outside="onCartClickOutside"
                        @mouseleave="cartWrapperHover = false"
                        class="cart-section"
                    />
                </div>
                <router-link
                    :to="{ name: user ? (isAdmin ? 'adminRoutes' : 'userRoutes') : 'login' }"
                    class="icon__wrapper"
                >
                    <UserSvg class="icon" />
                </router-link>
                <div class="menu__btn" @click="isSidebarOpen = true">
                    <span></span>
                </div>
                <div class="locale__wrapper">
                    <LocaleSwitcher />
                </div>
            </nav>
        </div>
        <div v-click-outside="onClickOutside" class="side-bar__wrapper" :class="{ active: isSidebarOpen }">
            <CRButton close class="close-btn" @click="isSidebarOpen = false" />
            <LogoFullSvg class="logo-icon" />
            <LocaleSwitcher is-mobile />
            <ul>
                <li v-for="(link, index) in navbarLinks" :key="index" @click="isSidebarOpen = false">
                    <router-link :to="{ name: link.linkName }">
                        {{ link.title }}
                    </router-link>
                </li>
            </ul>
            <div class="social__wrapper">
                <a href="https://www.facebook.com/cityrushfood" target="_blank" class="mr-10">
                    <FacebookSvg />
                </a>
                <a href="https://www.instagram.com/cityrushfood" target="_blank">
                    <InstagramSvg />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue';
    import vClickOutside from 'click-outside-vue3';
    import LogoFullSvg from '@/assets/icons/navbar/logo-full.svg?inline';
    import CartSvg from '@/assets/icons/navbar/cart-shopping.svg?inline';
    import InstagramSvg from '@/assets/icons/socials/instagram.svg?inline';
    import FacebookSvg from '@/assets/icons/socials/facebook-f.svg?inline';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CitySelectModal from '@/components/Modals/CitySelectModal.vue';
    import CRSelect from '@/components/Common/Select/CRSelect.vue';
    import UserSvg from '@/assets/icons/navbar/user.svg?inline';
    import LocationSvg from '@/assets/icons/navbar/location.svg?inline';
    import LocaleSwitcher from '@/components/Common/Navbar/components/LocaleSwitcher.vue';
    import CartHoverSection from '@/components/Common/Navbar/components/CartHoverSection.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Navbar',
        components: {
            LogoFullSvg,
            CartSvg,
            UserSvg,
            CRButton,
            InstagramSvg,
            FacebookSvg,
            LocaleSwitcher,
            CRSelect,
            LocationSvg,
            CitySelectModal,
            CartHoverSection,
        },
        directives: {
            clickOutside: vClickOutside.directive,
        },
        inject: ['provideApp'],
        data() {
            return {
                isSidebarOpen: false,
                isCityModalOpen: false,
                cartWrapperHover: false,
            };
        },
        setup() {
            const target = ref();
            const sticking = ref(false);
            const observer = new IntersectionObserver(
                ([entry]) => {
                    sticking.value = !entry.isIntersecting;
                },
                { threshold: 0.0 }
            );
            onMounted(() => {
                observer.observe(target.value);
            });
            return {
                target,
                sticking,
            };
        },
        computed: {
            ...mapGetters('cart', ['cart']),
            ...mapGetters('city', ['selectedCity', 'citiesForSelect']),
            ...mapGetters('auth', ['user', 'isAdmin']),

            navbarLinks() {
                return [
                    // { linkName: 'CristmasEveForm', title: this.$t('navbar.cristmasEveForm') },
                    { linkName: 'mealKits', title: this.$t('navbar.mealKits') },
                    { linkName: 'weeksMenu', title: this.$t('navbar.weeksMenu') },
                    { linkName: 'prices', title: this.$t('navbar.prices') },
                    { linkName: 'contacts', title: this.$t('navbar.contacts') },
                    { linkName: 'faq', title: this.$t('navbar.faq') },
                    { linkName: 'EUSupport', title: this.$t('navbar.eu') },
                ];
            },
        },
        methods: {
            ...mapActions('city', ['setSelectedCity']),

            onClickOutside() {
                this.isSidebarOpen = false;
            },

            onCartHover() {
                if (this.provideApp.$screenInnerWidth >= 1024) {
                    this.cartWrapperHover = true;
                }
            },

            onCartClickOutside() {
                if (this.provideApp.$screenInnerWidth >= 1024) {
                    this.cartWrapperHover = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    // navbar
    .navbar {
        @include row-align-center-justify-between;
        position: fixed;
        z-index: $index-500;
        top: 14px;

        width: calc(100% - 24px);

        background: $white;

        box-shadow: $box-shadow-dark;
        border-radius: $br-30;

        padding: 18px 20px;
        margin: 0 12px;

        .logo-link {
            @include row-align-center;

            margin-right: 24px;
        }

        .logo-icon {
            cursor: pointer;

            width: 120px;
            height: 25px;
        }

        .nav__wrapper {
            @include row-align-center;

            .icon-location:deep() {
                path {
                    fill: $primary;
                }
            }

            ul,
            .locale__wrapper,
            .city-select {
                display: none;
            }

            .select-city-icon {
                margin-right: 16px;
            }

            .icon__wrapper {
                @include row-align-center-justify-center;
                margin-right: 16px;

                position: relative;

                &.cart-icon__wrapper-active::after {
                    content: '';
                    display: block;

                    position: absolute;
                    right: -5px;
                    top: -5px;

                    width: 8px;
                    height: 8px;

                    background: $orange;

                    border-radius: $br-100;
                }

                .icon {
                    cursor: pointer;
                }

                &.active-link {
                    .icon :deep() {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }

            .menu__btn {
                position: relative;

                height: 16px;
                width: 24px;

                cursor: pointer;

                span,
                span::before,
                span::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    margin-top: -1px;
                    margin-left: -10px;

                    width: 20px;
                    height: 2px;

                    background-color: rgb(0, 0, 0);
                    border-radius: $br-3;
                }

                span::before,
                span::after {
                    content: '';
                    display: block;

                    transition: 0.2s;
                }

                span::before {
                    transform: translateY(-5px);
                }
                span::after {
                    transform: translateY(5px);
                }
            }
        }
    }

    @include media($lg) {
        .navbar {
            box-shadow: none;
            border-radius: 0;

            background: transparent;

            padding: 10px 55px;

            &.sticking {
                border-radius: $br-100;
                box-shadow: $box-shadow-dark;

                background: $white;
            }

            .nav__wrapper {
                @include row-align-center-justify-between;

                .city-select {
                    width: 140px;

                    display: block;
                }

                ul {
                    display: block;

                    li {
                        cursor: pointer;

                        display: inline;
                        margin-right: 24px;

                        font-weight: 700;
                        font-size: $font-14;

                        transition: all 0.2s linear;

                        &:hover {
                            color: $primary-200;
                            text-decoration-line: underline;
                        }

                        .active-link {
                            color: $primary;
                            text-decoration-line: underline;
                        }
                    }
                }

                .cart-icon__wrapper {
                    position: relative;
                    margin-right: 16px;

                    .cart-section {
                        top: 50px;
                        z-index: $index-1000;
                        position: absolute;
                        right: -110px;
                    }

                    .cart-icon__wrapper-active::after {
                        right: 6px;
                        top: 7px;
                    }

                    .icon__wrapper {
                        margin-right: 0;
                    }
                }

                .icon__wrapper {
                    @include row-align-center-justify-center;

                    box-shadow: $box-shadow-dark;
                    border-radius: $br-100;
                    width: 37px;
                    height: 37px;

                    margin-right: 16px;

                    transition: all 0.2s linear;

                    & &:hover {
                        background: $primary-500;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    .icon {
                        cursor: pointer;

                        width: 17px;
                        height: 19px;

                        &:deep() {
                            path {
                                fill: $primary;
                            }
                        }
                    }

                    &.active-link {
                        background: $primary;

                        .icon :deep() {
                            path {
                                fill: $white;
                            }
                        }
                    }
                }

                .menu__btn,
                .select-city-icon {
                    display: none;
                }

                .locale__wrapper {
                    display: block;

                    margin-left: 16px;
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: -16px;
                        top: 3px;

                        width: 1px;
                        height: 15px;

                        background: $grey-line;
                    }
                }
            }
        }
    }

    @include media($xxl) {
        .navbar {
            padding: 10px 155px;
        }
    }

    // side-bar
    .side-bar__wrapper {
        z-index: $index-1000;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;

        padding: 100px 0 0 36px;

        height: 100vh;
        width: 358px;

        background: $black;

        transform: scaleX(0) translateX(110%);
        transform-origin: right center;
        transition: all 0.3s ease-out;

        background-image: url('../../../assets/images/navbar/sidebar-bg.png');
        background-position: bottom -1px right -3px;
        background-repeat: no-repeat;
        background-size: auto;

        &.active {
            transform: scaleX(1) translateX(15%);
            transition: all 0.3s ease-out;
        }

        .close-btn {
            position: absolute;
            top: 10px;
            right: 65px;
        }

        .logo-icon :deep() {
            margin-bottom: 18px;

            path {
                fill: $white;
            }
        }

        ul li {
            cursor: pointer;

            margin-bottom: 16px;

            font-weight: 700;
            font-size: $font-18;

            color: $white;

            transition: all 0.2s linear;

            &:hover {
                color: $primary-200;
                text-decoration-line: underline;
            }

            .active-link {
                color: $primary;
                text-decoration-line: underline;
            }
        }

        .social__wrapper {
            @include row-align-center;
        }
    }
</style>
