<template>
    <div class="default-container">
        <Navbar />
        <div class="default-container__inner">
            <NavMenu :links-data="linksData" class="nav__wrapper" />
            <router-view class="content__wrapper" />
        </div>
    </div>
</template>

<script>
    import Navbar from '@/components/Common/Navbar/Navbar.vue';
    import NavMenu from '@/components/Common/NavMenu/NavMenu.vue';

    export default {
        name: 'AuthUserLayout',
        components: { Navbar, NavMenu },
        computed: {
            linksData() {
                return [
                    { name: 'kitManagement', title: this.$t('user.kitManagement'), hidden: false },
                    { name: 'userOrderManagement', title: this.$t('user.orderManagement'), hidden: false },
                    { name: 'allUserCheckOuts', title: this.$t('user.orderManagement'), hidden: true },
                    { name: 'userCheckOut', title: this.$t('navbar.orderManagement'), hidden: true },
                    { name: 'addresses', title: this.$t('user.addresses'), hidden: false },
                    { name: 'discounts', title: this.$t('navbar.discounts'), hidden: false },
                    { name: 'personalSettings', title: this.$t('user.personalSettings'), hidden: false },
                ];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .default-container {
        position: relative;

        min-height: 100vh;

        padding-top: 75px;
        padding-bottom: 70px;

        @include media($md) {
            padding-bottom: 0;
        }

        &__inner {
            padding: 0 12px;

            .nav__wrapper {
                margin-top: 15px;
            }
        }
    }

    @include media($lg) {
        .default-container {
            &__inner {
                @include row-align-start;

                padding: 0 55px;

                .nav__wrapper {
                    padding-top: 70px;

                    width: 345px;
                }

                .content__wrapper {
                    padding-top: 70px;

                    padding-left: 1px;
                    padding-right: 1px;

                    width: 100%;
                    overflow-y: scroll;
                    height: calc(100vh - 76px);

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }

    @include media($xxl) {
        .default-container {
            &__inner {
                padding: 0 155px;
            }
        }
    }
</style>
