import { PictureApi } from '@/api';
import { convertObjectKeys } from '@/helpers/ApiHelper';
import { getSign } from '@/helpers/SignHelper';
import { difference } from 'lodash';
import { cloneDeep } from 'lodash';

// Common
export const getDiscountDisplay = (discountAmount, discountType) => {
    return `-${discountAmount}${getSign(discountType)}`;
};

// For Admin
export const INITIAL_FORM = {
    name: {
        en: '',
        lt: '',
    },
    description: {
        en: '',
        lt: '',
    },
    isHidden: false,
    imageFiles: [],
    selectedDiscountId: null,

    // for regular
    selectedPeriods: [],
    selectedMealsIds: [],
    selectedMealsTypes: [],
    selectedCombinationsIds: [],
    selectedMenuTypesIds: [],
    calories: [],
    caloriesPeriodsPrices: {},

    // for unique
    price: '',
    uniqueMenus: [],
    lastOrderDay: '',
    deliveryDate: '',
    deliveryTimes: [],
};

export const prepareInitialDataForPage = async (routerName, currentMealKit) => {
    if (routerName === 'updateMealKit') {
        const {
            isUnique,
            price,
            name,
            description,
            isHidden,
            discount,
            periods,
            calories,
            combinations,
            meals,
            menuTypes,
            uniqueMenus,
            lastOrderDay,
            deliveryDate,
            deliveryTimes,
            pictures,
        } = currentMealKit;

        let form = {
            name: name,
            description: description,
            isHidden: isHidden,
            imageFiles: pictures,
            selectedDiscountId: discount ? discount.id : null,
        };

        if (!isUnique) {
            const combinationsIds = combinations.map((combination) => combination.id);
            const mealsIds = meals.map((meal) => meal.id);
            const mealsTypes = meals.map((meal) => meal.type);
            const menuTypesIds = menuTypes.map((menuType) => menuType.id);

            let caloriesPeriodsPrices = {};

            calories.forEach((element) => {
                caloriesPeriodsPrices[element.amount] = [];

                element.prices.forEach((priceItem) => {
                    const period = periods.find((period) => period.id === priceItem.period_id);
                    caloriesPeriodsPrices[element.amount].push({ period, price: priceItem.price });
                });
            });

            form = {
                ...form,
                // for regular
                ...{
                    selectedPeriods: periods,
                    selectedMealsIds: mealsIds,
                    selectedMealsTypes: mealsTypes,
                    selectedCombinationsIds: combinationsIds,
                    selectedMenuTypesIds: menuTypesIds,
                    calories,
                    caloriesPeriodsPrices,
                },
            };
        } else {
            form = {
                ...form,
                // for unique
                ...{
                    price,
                    uniqueMenus,
                    lastOrderDay,
                    deliveryDate,
                    deliveryTimes,
                },
            };
        }

        return {
            isUnique,
            form,
        };
    } else {
        return {
            form: cloneDeep(INITIAL_FORM),
            isUnique: false,
        };
    }
};

export const prepareDataForRequest = (form, isUnique, routerName) => {
    let formData = new FormData();

    form.imageFiles.forEach((element) => {
        formData.append('pictures[]', element);
    });

    let payload = {
        name: form.name,
        description: form.description,
        is_unique: isUnique,
        is_hidden: form.isHidden,
        discount_id: form.selectedDiscountId,
    };

    if (isUnique) {
        const preparedUniqueMenus = form.uniqueMenus.map((menu) => {
            return {
                name: menu.name,
                dish_id: menu.dish.id,
            };
        });

        const prepareDeliveryTimes = form.deliveryTimes.map((orderTime) => convertObjectKeys(orderTime));

        payload = {
            ...payload,
            ...{
                price: form.price,
                unique_menus: preparedUniqueMenus,
                last_order_day: form.lastOrderDay,
                delivery_date: form.deliveryDate,
                delivery_times: prepareDeliveryTimes,
            },
        };
    } else {
        const caloriesPeriodsPrices = cloneDeep(form.caloriesPeriodsPrices);
        const caloriesCopy = convertObjectKeys(cloneDeep(form.calories));

        Object.entries(caloriesPeriodsPrices).forEach((element) => {
            element[1].forEach((item) => {
                item.period = item.period.id;
            });
        });

        payload = {
            ...payload,
            ...{
                calories: caloriesCopy,
                menu_types_ids: form.selectedMenuTypesIds,
                meals_ids: form.selectedMealsIds,
                periods_ids: form.selectedPeriods.map((period) => period.id),
                combinations_ids: form.selectedCombinationsIds,
                calories_periods_prices: caloriesPeriodsPrices,
            },
        };
    }

    formData.append('payload', JSON.stringify(payload));

    if (routerName === 'updateMealKit') {
        return payload;
    } else {
        return formData;
    }
};

export const prepareMealKitFormDataValidation = (form, isUnique) => {
    const objCopy = { ...form };
    if (isUnique) {
        delete objCopy.selectedCombinationsIds;
        delete objCopy.selectedPeriods;
        delete objCopy.selectedMealsIds;
        delete objCopy.selectedMealsTypes;
        delete objCopy.selectedMenuTypesIds;
        delete objCopy.calories;
        delete objCopy.caloriesPeriodsPrices;
    } else {
        delete objCopy.uniqueMenus;
        delete objCopy.price;
        delete objCopy.lastOrderDay;
        delete objCopy.deliveryDate;
        delete objCopy.deliveryTimes;
    }

    delete objCopy.isHidden;
    delete objCopy.selectedDiscountId;
    delete objCopy.imageFiles;

    return objCopy;
};

export const uploadMealKitImage = async (images, mealKitId) => {
    let formData = new FormData();

    Array.from(images).forEach((element) => {
        formData.append('pictures[]', element);
    });

    formData.append('picturable_type', 'meal_kit');
    formData.append('picturable_id', mealKitId);

    const response = await PictureApi.store(formData);

    return response.data;
};

export const deleteMealKitImage = async (id) => {
    await PictureApi.destroy(id);
};

export const changeMealKitThumbnailImage = async (id) => {
    await PictureApi.makeThumbnail(id);
};

export const clearForm = (form) => {
    Object.entries(form).forEach(([key, val]) => {
        form = cloneDeep(INITIAL_FORM);

        if (Array.isArray(val)) {
            val.length = 0;
            form[key] = val;
        }
    });

    return form;
};

export const getFilteredCombinations = (combinations, selectedMealsTypes) => {
    const arr = [];

    combinations.forEach((element) => {
        if (!difference(element.combination, selectedMealsTypes).length) {
            arr.push(element);
        }
    });

    return arr;
};
