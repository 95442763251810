<template>
    <div class="nav-menu">
        <SlideDropDown :is-menu-slide="provideApp.$screenInnerWidth >= 1024" :is-nav-menu-opened="isNavMenuOpened">
            <template #title v-if="provideApp.$screenInnerWidth <= 1024">
                <router-link class="menu-link title" :to="{ name: selectedLink?.name }">
                    {{ selectedLink?.title }}
                </router-link>
            </template>
            <template #content>
                <router-link
                    class="menu-link"
                    v-for="link in neededArray"
                    :key="link.name"
                    :to="{ name: link.name }"
                    @click="setLink(link)"
                >
                    {{ link.title }}
                </router-link>
                <CRButton class="logout-button" pattern="thin" @click="handleLogout">
                    <template #icon>
                        <LogoutSVG />
                    </template>
                    {{ $t('common.logout') }}
                </CRButton>
            </template>
        </SlideDropDown>
    </div>
</template>

<script>
    import SlideDropDown from '@/components/Common/DropDown/SlideDropDown.vue';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import LogoutSVG from '@/assets/icons/buttons/logout.svg?inline';
    import { mapActions } from 'vuex';
    import { debounce } from 'lodash';

    export default {
        name: 'NavMenu',
        components: { CRButton, SlideDropDown, LogoutSVG },
        inject: ['provideApp'],
        props: {
            linksData: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                selectedLink: '',

                isNavMenuOpened: true,
            };
        },

        computed: {
            neededArray() {
                return (this.provideApp.$screenInnerWidth <= 1024 ? this.filteredLinksData : this.linksData).filter(
                    (item) => !item.hidden
                );
            },

            filteredLinksData() {
                return this.linksData.filter((item) => item.name !== this.selectedLink.name);
            },
        },
        methods: {
            ...mapActions('auth', ['logout']),

            setLink(link) {
                this.selectedLink = link;
            },

            async handleLogout() {
                try {
                    await this.logout();
                    this.$router.push({ name: 'login' });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            handleScroll: debounce(function () {
                if (window.scrollY > 0) {
                    this.isNavMenuOpened = false;
                }

                if (window.scrollY === 0) {
                    this.isNavMenuOpened = true;
                }
            }, 200),
        },

        mounted() {
            window.addEventListener('scroll', this.handleScroll);

            this.selectedLink = this.linksData.find((link) => link.name === this.$route.name);
        },

        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },
    };
</script>

<style lang="scss" scoped>
    // nav-menu
    .nav-menu {
        position: sticky;
        top: 90px;
        z-index: $index-200;

        .logout-button {
            justify-content: start;

            padding-top: 10px;
            margin-top: 15px;
            margin-bottom: 5px;

            width: 100%;

            border-radius: 0;

            border-top: solid $grey-line 1px;

            @include media($lg) {
                width: 50%;
            }
        }
    }

    // menu-link
    .menu-link {
        margin-top: 10px;
        padding: 8px 10px;
        border-radius: $br-20;

        background: $white;
        box-shadow: $box-shadow-dark;

        font-size: $font-14;
        font-weight: 700;

        @include media($lg) {
            &:first-child {
                margin-top: 0;
            }
        }

        &.title {
            margin-top: 0;
        }

        &.active-link {
            background: $primary;
            box-shadow: none;

            color: $white;
        }
    }
</style>
