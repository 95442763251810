<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2>{{ $t('placeholder.selectYourCity') }}</h2>
            <div class="button__wrapper">
                <CRButton
                    class="select-btn"
                    v-for="city in data"
                    :key="city.value"
                    pattern="section"
                    :active="city.value === selected"
                    @click="$emit('select', city)"
                >
                    {{ city.label }}
                </CRButton>
            </div>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';

    export default {
        name: 'CitySelectModal',
        components: { BasicModal, CRButton },
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            selected: {
                type: [String, Number],
                default: 0,
            },
        },
        emits: ['close', 'select'],
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-center-justify-center;

        width: 100%;

        padding: 28px 0;
        padding-bottom: 10px;

        h2 {
            font-size: $font-26;
            margin-bottom: 18px;
        }

        .button__wrapper {
            @include row-align-center-justify-around;
            flex-wrap: wrap;

            width: 300px;

            .select-btn {
                width: 30%;
                margin-bottom: 18px;
            }
        }
    }
</style>
