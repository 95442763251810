<template>
    <div>
        <div class="locale-switcher-desktop" v-click-outside="() => (isOpen = false)" v-if="!isMobile">
            <div class="select__wrapper" @click="toggleSelect">
                <p>{{ $i18n.locale }}</p>
                <ArrowSvg class="arrow-icon" :class="{ active: isOpen }" />
            </div>
            <div class="options__wrapper" :class="{ active: isOpen }">
                <p v-for="(locale, i) in locales" :key="`locale-${i}`" class="option" @click="selectLocale(locale)">
                    {{ locale }}
                </p>
            </div>
        </div>
        <div class="locale-switcher-mobile" v-else>
            <span
                v-for="(locale, i) in locales"
                :key="`locale-${i}`"
                class="locale"
                :class="{ selected: locale === $i18n.locale }"
                @click="setLocale(locale)"
            >
                {{ locale }}
            </span>
        </div>
    </div>
</template>

<script>
    import ArrowSvg from '@/assets/icons/arrows/arrow-down.svg?inline';
    import vClickOutside from 'click-outside-vue3';

    export default {
        name: 'LocaleSwitcher',
        components: { ArrowSvg },
        props: {
            isMobile: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                locales: ['lt', 'en'],
                isOpen: false,
            };
        },
        directives: {
            clickOutside: vClickOutside.directive,
        },
        methods: {
            toggleSelect() {
                this.isOpen = !this.isOpen;
            },

            selectLocale(locale) {
                this.setLocale(locale);

                this.toggleSelect();
            },

            setLocale(locale) {
                location.reload();

                localStorage.setItem('lang', locale);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .locale-switcher-desktop {
        position: relative;

        .select__wrapper {
            @include row-align-center;

            position: relative;
            cursor: pointer;
            padding-right: 20px;

            p {
                text-align: center;
                text-transform: uppercase;
                font-size: $font-14;
                font-weight: 700;

                min-width: 22px;

                color: $grey-form-label;
            }

            .arrow-icon {
                position: absolute;
                right: 0;
                top: 3px;

                transition: all 0.2s linear;

                &:deep() {
                    path {
                        stroke: $grey-form-label;
                        fill: $grey-form-label;
                    }
                }

                &.active {
                    transform: rotate(180deg);
                }
            }
        }

        .options__wrapper {
            @include column-align-center-justify-center;

            display: none;

            position: absolute;
            left: -9px;
            top: 25px;

            background: $white;
            box-shadow: $box-shadow-dark;

            border-radius: $br-15;

            &.active {
                display: block;
            }

            .option {
                cursor: pointer;

                padding: 6px 10px;

                text-align: center;
                text-transform: uppercase;

                font-size: $font-14;
                font-weight: 700;

                transition: all 0.2s linear;

                &:hover {
                    background: $grey;
                }

                &:first-child {
                    border-top-left-radius: $br-15;
                    border-top-right-radius: $br-15;
                }

                &:last-child {
                    border-bottom-left-radius: $br-15;
                    border-bottom-right-radius: $br-15;
                }
            }
        }
    }

    .locale-switcher-mobile {
        margin-bottom: 30px;

        .locale {
            text-align: center;
            text-transform: uppercase;
            text-decoration: underline transparent;

            font-size: $font-14;
            font-weight: 700;

            color: $grey-form-label;

            margin-right: 12px;

            transition: all 0.2s linear;

            &:last-child {
                margin-right: 0;
            }

            &.selected {
                color: $white;
                text-decoration: underline;
            }
        }
    }
</style>
