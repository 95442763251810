<template>
    <div class="cart-hover-section">
        <div class="cart-hover-section__content">
            <p v-if="!cart.length">
                {{ $t('cart.cartIsEmpty') }}
            </p>
            <div v-else>
                <CartCard
                    class="cart-card"
                    v-for="item in cart"
                    @edit-cart-item="editCartItem(item)"
                    :key="item.id"
                    :mealKit="item.mealKit"
                    :menu-type="item.menuType?.type"
                    :calorie="item.calorie"
                    :period="item.period"
                    :allergens="item.allergens"
                    :additions="item.additions"
                    :additional-allergen="item.additionalAllergen"
                    :amount="item.amount"
                    :total-price="item.totalPrice"
                    :meal-kit-price="item.mealKitPrice"
                    :combination="$filters.getTranslationByKey(item.combination?.name)"
                />
                <div class="total-section">
                    <h5>{{ $t('common.kitsTotal') }}</h5>
                    <h5>€{{ totalPrice }}</h5>
                </div>
            </div>
        </div>
        <CRButton class="w-full mb-10" pattern="outline" @click="toCartPage">
            {{ $t('buttons.viewCart') }}
        </CRButton>
        <CRButton class="w-full" @click="toCheckOutPage">
            {{ $t('buttons.checkout') }}
        </CRButton>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CartCard from '@/components/Cart/CartCard';
    import { cartMixin } from '@/utils/mixins';
    import { mapGetters } from 'vuex';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'CartHoverSection',
        components: { CartCard, CRButton },
        mixins: [cartMixin],
        computed: {
            ...mapGetters('cart', ['cart']),

            totalPrice() {
                const totalPrice = this.cart.reduce((accumulator, object) => {
                    return +accumulator + +object.totalPrice;
                }, 0);

                return totalPrice.toFixed(2);
            },
        },
        methods: {
            async toCheckOutPage() {
                this.$router.push({ name: 'checkOut' });

                fbq('track', ConversionEventNameEnum.INITIATE_CHECK_OUT);

            },

            toCartPage() {
                this.$router.push({ name: 'cart' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cart-hover-section {
        min-width: 345px;
        padding: 10px;
        background: $white;
        padding-right: 5px;

        box-shadow: $box-shadow-dark;
        border-radius: $br-5;


        p {
            text-align: center;
            font-style: italic;
        }

        .cart-card {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .total-section {
            @include row-align-center-justify-between;
            margin: 16px 0;
            padding-top: 16px;

            border-top: 1px solid $grey-line;
        }

        &__content {
            overflow: hidden;
            overflow-y: scroll;

            max-height: 423px;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: $grey;
                border-radius: $br-20;
            }
        }
    }
</style>
