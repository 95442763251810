<template>
    <div class="card">
        <div class="header">
            <div class="img" :style="backgroundStyles(mealKit.pictures[0]?.picturePath.small)">
                <div class="discount" v-if="mealKit.discount">
                    <h5>{{ getDiscountDisplay(mealKit.discount.amount, mealKit.discount.type) }}</h5>
                </div>
            </div>
            <div class="header__inner">
                <div class="header-top">
                    <div class="title__wrapper">
                        <h5>{{ $filters.getTranslationByKey(mealKit.name) }} -</h5>
                        <CRButton pattern="thin" @click.stop="$emit('editCartItem')">
                            {{ $t('buttons.updateLowercase') }}
                        </CRButton>
                    </div>
                    <CRButton @click="removeMealKitFromCart($.vnode.key)" close />
                </div>
                <div class="header-bottom">
                    <h5>€{{ mealKitPrice }}</h5>
                    <span>{{ forADaysTitle }}</span>
                </div>
            </div>
        </div>
        <div v-if="!mealKit.isUnique" class="body">
            <CRTag class="tag">{{ $t(`common.${menuType}`) }}</CRTag>
            <CRTag class="tag">{{ calorie.amount }}{{ $t('common.kcal') }}</CRTag>
            <CRTag class="tag">{{ $filters.getTranslationByKey(period.name) }}</CRTag>
            <CRTag class="tag">{{ combination }}</CRTag>
            <CRTag class="tag" v-for="allergen in allergens" :key="allergen.id">
                {{ renderAllergenName(false, allergen, additionalAllergen) }}
            </CRTag>
            <CRTag class="tag" v-for="addition in additions" :key="addition.id">
                {{ $filters.getTranslationByKey(addition.name) }}
            </CRTag>
        </div>
        <div class="footer">
            <CRSpin class="spin" v-model="kitAmount" :min="1" />
            <div class="footer__inner">
                <h5>{{ $t('common.subtotal') }}</h5>
                <h5>€{{ totalPrice }}</h5>
                <span>{{ forADaysTitle }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import CRSpin from '@/components/Common/Inputs/CRSpin.vue';
    import { getDiscountDisplay } from '@/helpers/MealKitHelper';
    import { renderAllergenName } from '@/helpers/AllergenHelper';
    import { backgroundStyles } from '@/helpers/CssHelper';
    import { mapActions } from 'vuex';

    export default {
        name: 'CartCard',
        components: { CRButton, CRTag, CRSpin },
        props: {
            mealKit: {
                type: Object,
                default: () => {},
            },
            combination: {
                type: String,
                default: '',
            },
            calorie: {
                type: Object,
                default: () => {},
            },
            period: {
                type: Object,
                default: () => {},
            },
            allergens: {
                type: Array,
                default: () => [],
            },
            additions: {
                type: Array,
                default: () => [],
            },
            menuType: {
                type: String,
                default: '',
            },
            additionalAllergen: {
                type: String,
                default: '',
            },
            amount: {
                type: Number,
                default: 1,
            },
            totalPrice: {
                type: String,
                default: '',
            },
            mealKitPrice: {
                type: String,
                default: '',
            },
        },
        emits: ['editCartItem'],
        data() {
            return {
                renderAllergenName,
                backgroundStyles,
                getDiscountDisplay,
                kitAmount: this.amount,
            };
        },
        watch: {
            amount(val) {
                this.kitAmount = val;
            },
            kitAmount(val) {
                this.updateMealKitInCart({
                    mealKit: this.mealKit,
                    amount: val,
                    id: this.$.vnode.key,
                });
            },
        },
        computed: {
            forADaysTitle() {
                return !this.mealKit.isUnique
                    ? this.$t('cart.forDays', { amount: this.period.amount })
                    : this.$t('cart.forADay');
            },
        },
        methods: {
            ...mapActions('cart', ['updateMealKitInCart', 'removeMealKitFromCart']),
        },
    };
</script>

<style lang="scss" scoped>
    .card {
        padding: 10px;

        box-shadow: $box-shadow-dark;
        border-radius: $br-10;

        .header {
            @include row-align-start-justify-between;

            border-bottom: 1px solid $grey-line;

            padding-bottom: 8px;

            .img {
                position: relative;

                height: 90px;
                width: 90px;

                border-radius: $br-10;

                background-image: url('../../assets/images/backgrounds/empty-mealKit-bg.png');

                .discount {
                    position: absolute;
                    top: 0;
                    left: 0;

                    padding: 5px 8px;

                    box-shadow: $box-shadow-dark;
                    background-color: $white;

                    border-bottom-right-radius: $br-10;
                    border-top-left-radius: $br-10;

                    h5 {
                        color: $danger;
                    }
                }
            }

            .header__inner {
                @include column-align-start-justify-between;

                width: calc(100% - 106px);
                height: 90px;

                .header-top {
                    @include row-align-start-justify-between;

                    width: 100%;

                    .title__wrapper {
                        @include row-align-center;
                        flex-wrap: wrap;

                        width: 80%;

                        h5 {
                            margin-right: 3px;
                        }
                    }
                }

                .header-bottom {
                    @include row-align-center;
                    flex-wrap: wrap;

                    span {
                        margin-left: 6px;

                        font-size: $font-14;
                        font-style: italic;
                    }
                }
            }
        }

        .body {
            @include row-align-center;
            flex-wrap: wrap;

            padding-top: 8px;

            border-bottom: 1px solid $grey-line;

            .tag {
                margin-right: 10px;
                margin-bottom: 8px;
            }
        }

        .footer {
            @include row-align-end-justify-between;

            padding-top: 8px;

            .footer__inner {
                @include column-align-end;

                h5 {
                    margin-bottom: 2px;
                }

                span {
                    font-size: $font-14;
                    font-style: italic;
                }
            }
        }
    }
</style>
