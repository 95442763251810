<template>
    <div class="drop-down" :class="{ 'menu-nav': isMenuSlide }">
        <div class="label__wrapper">
            <slot name="title" />
            <div class="icon-wrapper" @click="onSliderToggle" v-if="!isMenuSlide">
                <ArrowSvg class="arrow" :class="{ opened: isVisible }" />
            </div>
        </div>
        <transition name="accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
            <div class="accordion__content" v-show="isVisible || isMenuSlide">
                <slot name="content" />
            </div>
        </transition>
    </div>
</template>

<script>
    import ArrowSvg from '@/assets/icons/arrows/arrow-down.svg?inline';

    export default {
        name: 'SlideDropDown',
        components: { ArrowSvg },
        props: {
            isMenuSlide: {
                type: Boolean,
                default: false,
            },
            isNavMenuOpened: {
                type: Boolean,
                default: false,
            },
            isExtendAll: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['onSliderToggle'],
        data() {
            return {
                isVisible: true,
            };
        },
        watch: {
            isExtendAll: {
                handler(val) {
                    this.isVisible = val;
                },
                immediate: true,
            },
            isNavMenuOpened: {
                handler(val) {
                    if (!this.isMenuSlide) {
                        this.isVisible = val;
                    }
                },
                immediate: true,
            },
        },
        methods: {
            onSliderToggle() {
                this.isVisible = !this.isVisible;
                this.$emit('onSliderToggle', this.isVisible);
            },
            start(el) {
                el.style.height = el.scrollHeight + 'px';
            },
            end(el) {
                el.style.height = '';
            },
        },
    };
</script>

<style lang="scss" scoped>
    // animation
    .accordion-enter-active,
    .accordion-leave-active {
        will-change: height, opacity;
        transition: height 0.3s ease, opacity 0.3s ease;
        overflow: hidden;
    }

    .accordion-enter-from,
    .accordion-leave-to {
        height: 0 !important;
        opacity: 0;
    }

    .drop-down {
        padding: 6px;
        border-radius: $br-20;

        background: $white;
        box-shadow: $box-shadow-dark;

        &.menu-nav {
            box-shadow: none;
            padding: 0;
        }

        .label__wrapper {
            @include row-align-center-justify-between;

            .icon-wrapper {
                @include row-align-center-justify-center;

                background: $primary;
                border-radius: $br-100;

                width: 37px;
                height: 37px;

                cursor: pointer;

                .arrow {
                    transition: all 0.2s linear;

                    &:deep() {
                        width: 20px;
                        height: 20px;

                        path {
                            stroke: $white;
                            fill: $white;
                        }
                    }

                    &.opened {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .accordion__content {
            @include column-align-start;
            padding: 1px;

            .menu-link {
                margin-top: 10px;
            }
        }
    }
</style>
